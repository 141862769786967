<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card :title="heading">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">

                                <b-row>
                                    <b-col md="2">Defect Image<span class="text-danger">*</span></b-col>
                                    <b-col md="4">Defect Item<span class="text-danger">*</span></b-col>
                                    <b-col md="5">Defect Type<span class="text-danger">*</span></b-col>
                                </b-row>

                                <b-row v-for="(fm, index) in form" :key="index" class="mt-1">
                                    <b-col md="2">
                                        <AddImage v-bind:index="index"/>
                                    </b-col>
                                    <b-col md="4">
                                        <b-row>
                                            <b-col md="12">
                                                <b-form-input size="lg" placeholder="3 to 200 characters" v-model="fm.defect_name" />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="5">
                                        <b-row>
                                            <b-col md="12">
                                                <b-form-tags v-model="fm.defect_type" input-id="tags-remove-on-delete" separator=",;" tag-pills remove-on-delete no-add-on-enter size="lg" placeholder="Enter defect type separated by comma or semicolon." title="Enter defect type separated by comma or semicolon."/>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="1" class="text-center">
                                        <b-button variant="danger" @click="removeRow(index);" class="under_construct_mobile_danger_btn">-</b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="mt-1">

                                    <b-col md="12" class="text-center">
                                        <hr>
                                        <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'defect-type'})">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BFormTags, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';
    import AddImage from './AddImage.vue';
    import Bus from "../../../event-bus";

    export default {
        components: {
            BFormTags, AddImage, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                form :[
                    {
                        defect_type : null,
                        defect_name : null,
                        defect_image : null,
                    }
                ],

                // Page Labels
                heading : 'Add Defect Type',
                api     : '/api/add-defect-type',
            }
        },

        methods : {
            formSubmit(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : this.api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'defect-type'})
                        });
                    }
                });
            },

            addRow(){
                this.form.push({
                    defect_type : null,
                    defect_name : null,
                    defect_image : null,
                })
            },

            removeRow(index) {
                if(this.form.length > 1){
                    this.form.splice(index, 1);
                }
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'conservancy-supervisor-daily-report'},
                text:'Conservancy Supervisor Daily Report',
                
              },{
                to:{name:'defect-type'},
                text:'Defect Type',                
              },{
                to:null,
                text:'Add Defect Type',                
              }];
              return item;
            }
        },
        mounted(){
            // to add image
            Bus.$on('update-image', (data) => {
                this.form[data.index].defect_image = data.data;
            })
        }
    }
</script>
